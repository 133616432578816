html {
    overflow-x: hidden;
}

header {
    position: relative;
}

a {
    color: #fff;
    text-decoration: none;
}

.recaptchaDiv{
   
    margin-bottom: 10px;
}
.starratingawesome:not(:checked) > input{
    position: absolute;
    clip: rect(0,0,0,0);
}
.wpcf7-starratingawesome.starratingawesome {
    clear: both;
    display: inline-block;
    float: none;
    padding: 0;
    margin: 5px 0 10px;
    border: none;
}
#CompanyDiv .col-sm-6 {
    /*-ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;*/
}
a:hover {
    text-decoration: none;
    color: #fff;
}
p>a {
	color: #000091;
}
p>a:hover {
	color: #000091;
	text-decoration: underline;
}

body {
    padding: 0;
    margin: 0;
    position: relative;
}


*,
*:after,
*:before {
    box-sizing: border-box;
}

header {
    background-color: #fdfdfe;
    border-bottom: 5px solid #000091;
    padding-top: 15px;
    /* padding-left: 30px; */
    z-index: 1;
    border-top: 30px solid #da291c;
}
header.blue {
    background-color: #000091;
    border-bottom: 5px solid #000091;
    padding-top: 15px;
    /* padding-left: 30px; */
    z-index: 1;
    border-top: 30px solid #000091;
}
header.green {
    background-color: #38B271;
    border-bottom: 5px solid #38B271;
    padding-top: 15px;
    /* padding-left: 30px; */
    z-index: 1;
    border-top: 30px solid #38B271;
}
header span {
    color: white;
}

/*header div div:first-child span {
    border-left: 1px solid #fff;
    padding-left: 15px;
    line-height: 36px;
    margin-left: 15px;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
}

header div div:last-child span {
    font-size: 20px;
    font-weight: 700;
}*/

.main-image-container {
    position: relative;
    width: 100%;
}

.main-image-container img {
    width: 100%;
}

.career-search {
    position: absolute;
    top: 55%;
    left: 10%;

}

.career-search h1 {
    color: #fff;
    font-size: 26px;
    width: 370px;
    font-weight: 700;
}

.career-search form input {
    border-radius: 30px 0 0 30px;
    font-size: 9px;
    padding-left: 10px;
    font-weight: 700;
    width: 189px;
    height: 40px;
    outline: none;
    border: none;
}





header .bottom-header {
    border-top: 1px solid #0578BD;
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 174px;
    background-color: #07378E;
    margin-left: -30px;
    padding-right: 30px;
}

header .row:last-child ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 22px;
}

header .row:last-child a {
    color: #D3D7E4;
    font-weight: 700;
    font-size: 15px;
    font-style: italic;
/*    max-width: 157px;*/
    position: relative;
}

header .row:last-child a:hover {
    color: #fff;

}

.about-us-image-container {
    position: relative;
}

.about-us-image-container img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.about-us-section {
    background-color: #fff;
    background: #fff;
    height: 100%;
    padding: 20px;
}

.about-us-section span {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;

}

.about-us-section h2,
.career-content h2 {
    color: #04348B;
    font-weight: 700;
    padding: 10px 0;
    border-bottom: 1px solid #AEB2C5;
    font-size: 40px;
}

.about-us-section p {
    margin-top: 10px;
    line-height: 30px;
}

.career-content {
    background-color: #C1C6C8;
}

.career-content section {
    background-color: #EDEEEF;
    padding: 20px;
}



.brand-container {
    margin: 20px 0;
    border-top: 1px solid #AEAFAF;
    padding-top: 20px;
    padding-bottom: 20px;
}

.brand-container div:first-child{
    padding-left: 10px;
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}


.brand-container div:first-child img {
    max-width: 200px;
}

.career-border {
    border-top: 1px solid #AECAE7;
    min-height: 1px;
    width: 100%;
}

.our-brands-section > div:first-child {
    border: none;
}

.no-border {
    border: none;
}

.offers img {
    width: 100%;
}

.offer-item {
    padding: 10px;
    margin: 10px 0;
}

.offer-item > div > div:first-child {
    display: flex;
    align-items: center;
}

/*.empl-container > div {
    background-color: #fff;
    padding: 10px;
    margin: 20px 10px;
}*/

.empl-photo-container img{
    border-radius: 50%;
    width: 103px;
    height: 103px;
    margin-right: 10px;
}

.empl-photo-container {
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 3px;
}

.empl-feedback img{
    width: 100%;
}

.empl-feedback span {
    font-size: 13px;
    text-align: left;
}

.bottom-header img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    opacity: 0.5;
    max-height: 290px;
}

.bottom-header {
    padding-top: 40px;
    padding-bottom: 70px;
    position: relative;
}

 header .bottom-header h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    padding: 20px 0;
    font-style: italic;
}

.career-container > div.container.feedback-form {
    padding: 10px 90px 100px 90px;
    background-color: #FDFDFE;
    max-width: 1140px;
    margin: 0 auto 0 auto;
}

.career-container-feedback > div {
    padding: 10px 17%;
    background-color: #fdfdfe;
}


.career-container-feedback, .career-container, .career-container-2 {
    background-color: #BFC4C7;
    min-height: 100vh;
}

.career-content-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #BABDBE;
    margin-bottom: 18px;
}

.career-content-item h3{
    color: #0C388B;
}

.feedback-form {
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

.feedback-form h1 {
    text-align: center;
    color: #da291c;
    font-style: italic;
    font-weight: 700;
}
.feedback-form h1.blue {
    text-align: center;
    color: blue;
    font-style: italic;
    font-weight: 700;
}
.feedback-form h1.green {
    text-align: center;
    color: #38B271;
    font-style: italic;
    font-weight: 700;
}

.add-files {
    background-color: #fff;
    width: 100%;
    height: 100px;
    border: 2px dashed #B1B1B1;
    margin: 15px 0;
    text-align: center;
    line-height: 6;
}
.upload-file {
    height: 0;
    width: 0;
}
.career-container .searchform {
	width: 100%;
}
.career-container .searchform input[type=text] {
	width: 50%;
    display: inline-block;
    vertical-align: top;
}
.career-container .searchform select {
    width: 29%;
    display: inline-block;
    vertical-align: top;
    height: 30px;
}
.career-container .searchform input[type=submit] {
    width: 12%;
    display: inline-block;
    vertical-align: top;
    border: 0;
    background-color: #07378e;
    color: #fff;
    font-size: 19px;
}


.form-sub-button {
    background-color: #003087;
    width: 170px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 30px;
    margin-top: 20px;
    border:none;
    height: 40px;
}

.form-sub-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.feedback-form input:focus,
.feedback-form button:focus {
    outline: none;
}

.pagal-container {
    position: relative;
}

.pagal-container img {
    width: 100%;
}

.pagal-description {
    position: absolute;
    bottom:40px;
    right: 0;
    font-size: 12px;
}

.career-container-2 > div {
    padding: 20px 50px;
    background-color: #fff;
}

.right-block-career {
    padding-top: 60px;
    border-radius: 30px;
    margin-top: -60px;
    background-color: #BFC4C7;
    padding-bottom: 30px;
}

.right-block-career img{
    width: 100%;
}

.right-block-career > div > div {
    margin: 20px 0;
}

.right-block-career > ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;

}

.right-block-career > ul li {
    font-size: 15px;
    font-weight: 700;
}

.map {
    width: 80%;
    margin: 0 auto;
}

.left-block-career p span {
    font-weight: 700;
    font-size: 16px;
    color: #013188;
}

.left-block-career > span {
font-size: 14px;
}

.left-block-career .pagal-info {
    margin: 22px 0;
    font-size: 19px;
    line-height: 70px;
}

.top-links {
    position: relative;
    display: flex;
}

.top-links .big-arrow {
    width: 40px;
    height: 40px;
    border-bottom: 1px solid #0578BD;
    border-right: 1px solid #0578BD;
    position: absolute;
    transform: rotate(45deg);
    z-index: 20;
    background-color: #003087;
}

.big-arrow-career {
    bottom: -41px;
    left: 205px;
}

.big-arrow-about {
    bottom: -41px;
    left: 116px;
}

.top-links a{
    position: relative;
    font-style: italic;
    font-weight: 700;
    color: #D3D7E4;
}

.top-links a:first-child {
    margin-right: 23px;

}

.top-links a img {
    margin-left: 7px;
    margin-top: -2px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.main-header {
    padding-bottom:30px;
}

.main-mob-image {
	display:none;
}





.row {
    margin: 0 -15px;
}
/*.row {
    margin: 0;
}*/

.feedback-form,
.form-sub-button {
	text-transform: uppercase;
}


.bottom-header .menu-arrow {
    position: absolute;
    left: -17px;
    bottom: 10px;
    top: 4px;
    width: 12px;
    height: 12px;
    opacity: 1;
    display: none;
}

.main-nav:hover .menu-arrow {
	display: inline-block;
}


.cd-select {
	position: absolute;
	 height: 40px;
    margin-bottom: -18px;
    padding-left: 10px;
    border-radius: 0 30px 30px 0;
    border: none;
    font-size: 9px;
    color: #757575;
    font-weight: 700;
    width: 200px;
}

.cd-dropdown option {
	margin: 10px 0;
	height: 40px;
	border-radius: 30px;

}

.cd-select:focus {
	outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.arrow-down-location {
	position: absolute;
	left: 30px;
	top: 0;

}
@media screen and (max-width: 960px) {
.career-send {
    position: absolute;
    top: 80% !important;
    left: 10%;
}
.dropdownmenu {
    position: relative;
    display: inline-block;
    outline: none;
    margin-left: 37px;
}
}
@media screen and (max-width: 768px) {
    .right-block-career > div > div {
    padding: 0;
    padding-right: 5px;
}
.career-send {
    position: absolute;
    top: 80%;
    left: 10%;
}
    .pagal-description {
        bottom: 5px;
    }

    .big-arrow-career {
        left: 170px;
    }

    .top-links a {
    font-size: 15px;
	}

	.brand-container div:first-child img {
    max-width: 147px;
	}

	.top-links .big-arrow {
    width: 25px;
    height: 25px;
	}

	.big-arrow-career {
   		 left: 200px;
	}

	.big-arrow-career {
    	bottom: -33px;
   		left: 205px;
	}

	.big-arrow-about {
    bottom: -33px;
    left: 127px;
	}

	.about-us-section span {
   		 line-height: 38px;
	}

	.about-us-section p {
    line-height: 25px;
	}
}

@media screen and (max-width: 425px) {
    .empl-photo-container {
        justify-content: center;
    }

    .brand-container div:first-child {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
    }

    .bottom-header img {
        display: none;
    }

    .right-block-career {
        margin:0;
        padding-top: 0;
        margin-top: 20px;
    }

    .pagal-description {
        bottom: 5px;
    }

    .top-links {
        margin-top: 20px;
    }

    .main-mob-image {
		display: inline-block;
	}

	.main-image {
		display: none;
	}

	.about-us-section span {
    	line-height: 25px;
    	font-size: 22px;
	}

	header .bottom-header {
	    text-align: center;
	        padding-bottom: 38px;
	}

	.career-container > div {
    padding: 8px 27px;
	}

	h3 {
    font-size: 1.55rem;
	}

	.career-container-feedback > div {
    	padding: 10px 5%;
	}
		header {
		padding-left: 5px;
	}
.dropbutton img {
    margin-left: 7px;
    margin-top: -2px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 27px;
}
.dropdownmenu button {
    border: 0;
    background-color: #003087;
    color: #D3D7E4;
    font-weight: 700;
    display: block;
    font-size: 15px;
    font-style: italic;
    width: 28px;
    text-align: left;
    /* height: 31px; */
    outline: none;
    position: relative;
}
.career-send {
    position: absolute;
    top: 84% !important;
    left: 10%;
}
}

@media screen and (max-width: 320px) {
	.pagal-description {
    	font-size: 9px;
	}

	header div div:first-child span {
    	font-size: 18px;
	}

	header {
		padding-left: 5px;
	}
.career-send {
    position: absolute;
    top: 80%;
    left: 10%;
}
.dropbutton img {
    margin-left: 7px;
    margin-top: -2px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 27px;
}
.dropdownmenu button {
    border: 0;
    background-color: #003087;
    color: #D3D7E4;
    font-weight: 700;
    display: block;
    font-size: 15px;
    font-style: italic;
    width: 28px;
    text-align: left;
    /* height: 31px; */
    outline: none;
    position: relative;
}
	}
	.career-container-2 > div {
   		 padding: 20px;
  	 	 background-color: #fff;
	}

	.career-search form input {
   	 font-size: 7px;
   	 width: 153px;
	}

	.cd-select {
    font-size: 7px;
    width: 97px;
}

 	header .bottom-header {
 		margin-left: 0;
  		padding-right: 0;
 	}



.dropbutton {
    background-color: #4CAF50;
    color: white;
    padding: 14px;
    font-size: 14px;
    border: none;
    cursor: pointer;

}

.dropbutton:hover, .dropbutton:focus {
    background-color: #003087;
    background-color: transparent;
}

.dropdownmenu {
    position: relative;
    display: inline-block;
    outline: none;
    margin-left: 15px;
}
.dropbutton img {
    margin-left: 7px;
    margin-top: -2px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.dropdownmenu-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 53px;
    overflow: auto;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.3);
        z-index: 9;
            top: 80px;
}

.dropdownmenu-content a {
    color: black;
    padding: 12px 14px;
    text-decoration: none;
    display: block;
}

.dropdownmenu a:hover {
    background-color: #003087;
	color: #fff;
}

.show {
    display:block;
}
.dropdownmenu button {
    border: 0;
    background-color: #003087;
    color: #D3D7E4;
    font-weight: 700;
    display: block;
    font-size: 15px;
    font-style: italic;
    width: 28px;
    text-align: left;
    /* height: 31px; */
    outline: none;    
    padding-bottom: 27px;
    background-color: transparent;
}
.career-send {
	position: absolute;
    top: 69%;
    left: 10%;
}
.career-send a {
	border-radius: 30px;
    background-color: #003087;
    display: block;
    width: 139px;
    text-align: center;
    line-height: 3;
}
.dropdownmenu a:first-child {
    margin-right: 0px;
}
.dropdownmenu a {
	color: #000 !important;
}
.dropdownmenu a:hover {
    color: #fff !important;
}
.feedback-form label{
	width: 100%;
}
.feedback-form input[type="text"] {
    overflow: visible;
    width: 100%;
}
.feedback-form input[type="email"] {
    overflow: visible;
    width: 100%;
}
.feedback-form input[type="tel"] {
    overflow: visible;
    width: 100%;
}
.qbutton {
	display:none;
}
.menu-top-menu-container {
    display: none;
    position: absolute;
    width: 165px;
    background-color: #003087;
    height: 198px;
    margin-top: 50px;
    padding: 15px;
    left: 0;
    z-index: 100;
}
.menu-top-menu-container ul {
    list-style: none;
    padding: 0;
}
#menu-top-menu {
    padding-top: 0;
}
.career-search form input {
    font-size: 12px;
    width: 230px;
}

.cd-select {
    font-size: 12px;
    width: 97px;
}

.career-container form#searchform {
    margin: 40px 0 60px 0;
}
.career-container form#searchform input[type=text] {
    height: 40px;
    float: left;
    padding: 0 10px;
    border-radius: 20px 0 0 20px;
    border: 1px solid #9E9E9E;
}
.career-container form#searchform select {
    width: 25%;
    height: 40px;
    float: left;
    padding: 0 10px;
    border-radius: 0 20px 20px 0;
    border: 1px solid #9E9E9E;
}
.career-container form#searchform input[type=submit] {
    width: 20%;
    height: 40px;
    float: right;
    border-radius: 20px;
    border: 1px solid #07378e;
}
.career-container form#searchform input[type=submit]:hover {
    background: #294d8c;
}

.menu-top-menu-container {
    display: none;
    position: absolute;
    width: 100%;
     background-color: rgba(0, 48, 135, 1);
    height: 198px;
    margin-top: 80px;
    padding: 25px;
    left: 0;
    z-index: 100;    
    border-top: 1px solid #04a5d4;
}
.menu-top-menu-container:before {
    position: absolute;
    content: '';
    background: #003087;
    width: 2000px;
    top: -1px;
    right: 100%;
    height: calc(100% + 1px);
    border-top: 1px solid #04a5d4;
}
.menu-top-menu-container:after {
    position: absolute;
    content: '';
    background: #003087;
    width: 1000px;
    top: -1px;
    left: 100%;
    height: calc(100% + 1px);    
    border-top: 1px solid #04a5d4;
}
.top-links a.why_class.aft:after {
    content: '';
    display: block;
    width: 26px;
    height: 26px;
    background: #003087;
    border-right: 2px solid #04a5d4;
    border-bottom: 2px solid #04a5d4;
    position: absolute;
    top: 68px;
    right: -5px;
    z-index: 999;
    transform: rotate(45deg);
}
ul#menu-top-menu li:before {
    display: none;
}
ul#menu-top-menu li:hover:before {
    content: '';
    /*background: url(../images/down-arrow-header.png);*/
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 16px;
    height: 14px;
    left: -12px;
    top: 7px;
    transform: rotate(-90deg);
    display: block;
}

ul#menu-top-menu li {
    /*border-bottom: 1px solid #749cde;*/
    padding: 3px 8px;
    position: relative;
}




.menu-top-menu-lt-container {
    display: none;
    position: absolute;
    width: 100%;
     background-color: rgba(0, 48, 135, 1);
    height: 198px;
    margin-top: 80px;
    padding: 25px;
    left: 0;
    z-index: 100;    
    border-top: 1px solid #04a5d4;
}
.menu-top-menu-lt-container:before {
    position: absolute;
    content: '';
    background: #003087;
    width: 2000px;
    top: -1px;
    right: 100%;
    height: calc(100% + 1px);
    border-top: 1px solid #04a5d4;
}
.menu-top-menu-lt-container:after {
    position: absolute;
    content: '';
    background: #003087;
    width: 1000px;
    top: -1px;
    left: 100%;
    height: calc(100% + 1px);    
    border-top: 1px solid #04a5d4;
}
.top-links a.why_class.aft:after {
    content: '';
    display: block;
    width: 26px;
    height: 26px;
    background: #003087;
    border-right: 2px solid #04a5d4;
    border-bottom: 2px solid #04a5d4;
    position: absolute;
    top: 68px;
    right: -5px;
    z-index: 999;
    transform: rotate(45deg);
}
ul#menu-top-menu-lt li:before {
    display: none;
}
ul#menu-top-menu-lt li:hover:before {
    content: '';
    /*background: url(../images/down-arrow-header.png);*/
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 16px;
    height: 14px;
    left: -12px;
    top: 7px;
    transform: rotate(-90deg);
    display: block;
}

ul#menu-top-menu-lt li {
    /*border-bottom: 1px solid #749cde;*/
    padding: 3px 8px;
    position: relative;
}
#menu-top-menu-lt {
    padding-top: 0;
}
.menu-top-menu-lt-container ul {
    list-style: none;
    padding: 0;
}


.col-sm-6.offer-item .col-sm-10 {
    display:flex;
    align-items:center;;
}
.col-sm-6.offer-item .col-sm-10 span p {
    margin: 0;
}
span.wpcf7-list-item-label a {
    color: #007bff;
    text-decoration: underline !important;
}
span.wpcf7-list-item-label a:hover {
    color: #003087 !important;
    text-decoration: underline !important;
}
.col-sm-12.career-content-item h3 {
    margin: 30px 0;
}

a.searchmore {
    color: #007bff;
    text-decoration: underline !important;
    position: absolute;
    bottom: 15px;
    right: 30px;
    font-weight: 700;
}
a.searchmore:hover {
    color: #003087 !important;
    text-decoration: underline !important;
}

.about-us-image-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.about-us-image-container > .container {
    /*background-color: #fff;*/
}

.main-image-container .searchform {
    position: relative;
}

.main-image-container .searchform:before {
    content: '';
    display: block;
    width: 2px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 230px;
    background-color: #757575;
    z-index: 1;
}

/* ============================ */
#videyoutube iframe {
    opacity: 1;
    visibility: visible;
    width: 101%;
    height: 100%;
    position: absolute;
    left: -2px;
    top: -9%;
    display: block;
}
#videyoutube {
    position: relative;
    margin-bottom: -60px;
    padding-bottom: 42%;
}


#frame {
    overflow: hidden;
    width: 100%;
    height: 608px;
    margin-top: 0px;
}
#frame iframe {
    border: 0;
    margin-top: -32px;
}

.container_block {
    width: 100%;
    padding: 0 5%;
}
section {
    background: #eee;
    border-bottom: 1px solid #ccc;
}
section h2 {
    font-size: 60px;
    line-height: 80px;
    padding-top: 20px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    color: #003087;
    display: block;
    width: 100%;
    border-bottom: 2px solid #003087;
}

.textvideo {
    background: #fff;
    padding: 20px;
}
.textarea {
    display: inline-block;
    width: 35%;
    vertical-align: top;
    font-size: 20px;
    padding-right: 20px;
}
.videoarea {
    display: inline-block;
    width: 64%;
    vertical-align: top;
}
.videoarea iframe {
    width: 100%;
    min-height: 360px;
}

.img-responsive {
    width: 100%;
    height: auto;
}
.row_images {
    margin-top: 30px;
    margin-bottom: 30px;
}
.statistics_images {
    margin-bottom: 30px;
    margin-top: 30px;
}
.awards_images {
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
    display: block;
}
.image_item {
    display: inline-block;
    max-width: 300px;
}
.row_core.row {
    padding: 20px 0;
}
.core_item {
    background: #fff;
    padding: 15px;
    margin-bottom: 30px;
}
.core_item .core_name {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 34px;
    min-height: 40px;
}
.core_item .core_descr {
    font-size: 16px;
    min-height: 50px;
}
.brand_item {
    margin-bottom: 15px;
    min-height: 100px;
}
.brand_item img {
    margin: 30px auto 0px auto;
    /*width: 70%;*/
    display: block;
    height: auto;
    width: auto;
    width: 100%;
}
a.brand_link {
    float: right;
    margin: 0px 10px 50px 10px;
    height: 40px;
    padding: 0px 20px;
    border-radius: 20px;
    color: #696969;
    border: 1px solid #696969;
    font-size: 16px;
    line-height: 37px;
}

.offer_item.row {
    background: #fff;
    margin-bottom: 20px;
}
.icontext.col-sm-6 img {
    max-height: 140px;
    width: auto;
    margin: 50px auto;
    display: block;
}
span.offer_text {
    text-align: center;
    display: block;
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 600;
    color: #003087;
}
.offerimage.col-sm-6 img {
    max-height: 360px;
    width: auto;
    width: 100%;
    margin: 8px 0;
}
.offerimage.col-sm-6 iframe {
    max-height: 360px;
    width: auto;
    width: 100%;
    margin: 8px 0;
}
.offer_item.row {
    background: #fff;
    max-width: 1130px;
    margin: 10px auto !important;
}

.row_offer.row > div.col-sm-12:nth-child(2n) > div.offer_item.row {
    flex-direction: row-reverse;
}

.post-item {
    background: #fff;
    padding: 10px;
}
.post_title {
    font-size: 18px;
    line-height: 40px;
}
.post_title a {
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
}
.post_title a:hover {
    color: #003087 !important;
}

.row.main-header {
    margin: 0 30px !important;
}
.empl-container > div+div {
    background-color: #fff;
    padding: 10px;
    margin: 20px 0px !important;
}

.empl-container > div > div.empl-info {
    text-align: center;
    margin-top: 20px;
    margin-bottom: -10px;
}
img.emp_flag {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    border-radius: 20px;
}
.emp_name {
    font-size: 16px;
    line-height: 20px;
}
.emp_rol {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
}
.post-item.mini {
    margin-bottom: 20px;
}
.post-item.mini .post_descr.short {
    max-height: 80px;
}
.row_posts.row {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.row_images {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.videocore iframe {
    width: 100%;
    height: auto;
    min-height: 289px;
}
.offervideo iframe {
    width: 100%;
    height: auto;
    min-height: 360px;
    padding: 8px 0;
    border: none;
}

.career-form {
    margin-top: 50px;
}
.career-form h1 {
    text-align: center;
    font-size: 30px;
}
.career-form div.wpcf7 {
    padding: 15px;
}

.career-form span.wpcf7-list-item-label {
    font-size: 12px;
}
.career-benefits .icontext img {
    width: 25%;
    float: left;
}
.career-benefits .icontext span {
    width: 74%;
    float: right;
}
.career-benefits .icontext span {
    width: 74%;
    float: right;
    font-size: 16px;
    padding: 20px 0;
}
.career-benefits .icontext {
    margin: 20px 0;
}
.career-benefits {
    margin-top: 50px;
    margin-bottom: 20px;
}
.career-title {
    color: #003087;
    margin-top: 35px;
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 600;
}

.wpcf7-form-control-wrap input {
    width: 100%;
}
.career-benefits  .offer_item.row {
    background: #efefef;
    max-width: 1130px;
    margin: 0px auto !important;
    margin-right: 20px !important;
}
.career-benefits .icontext {
    margin: 0px 0; 
}
.career-benefits .icontext img {
    width: 12%;
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 20px;
}
.career-benefits .icontext span {
    width: 80%;
    float: right;
    font-size: 14px;
    padding: 14px 0;
    text-transform: unset;
    color: #000;
    text-align: left;
}
.career-form h1 {
    text-align: center;
    font-size: 22px;
    background: #efefef;
    padding: 10px;
    margin-bottom: 20px;
}
.career-form div.wpcf7 {
    padding: 15px;
    padding-top: 0;
}
/*.career-form label {
    display: none;
}*/
.career-form label.add-files {
    display: block;
}
.career-form .wpcf7-form-control-wrap {
    margin-bottom: 20px;
}
.career-form .wpcf7-form-control-wrap {
    margin-bottom: 20px;
    display: block;
}
span.wpcf7-form-control-wrap.checkbox-635 {
    display: unset;
}
span.wpcf7-form-control-wrap.checkbox-635 input {
    width: unset;
    float: left;
}

.form-sub-button {
    background-color: #1eb2e7;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
     border-radius: 0px; 
    margin-top: 20px;
    border: none;
    height: 40px;
}

.col-sm-9.empl-feedback img {
    max-width: 26px;
    margin: 0 auto;
    text-align: center;
}
.col-sm-9.empl-feedback {
    text-align: center;
}

.post-item.mini {
    margin-bottom: 20px;
    min-height: 424px;
}
.empl-container > div+div {
    background-color: #fff;
    padding: 10px;
    margin: 20px 0px !important;
    min-height: 250px;
}
.post-item.mini {
    margin-bottom: 20px;
    min-height: 300px;
    text-align: center;
}
.post_title {
    font-size: 18px;
    line-height: 40px;
    text-align: left;
}
.post-item.mini .post_descr.short {
    max-height: 80px;
    text-align: left;
}
.post-item.mini img {
    max-height: 150px;
    width: auto;
        max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.post-item {
    background: #fff;
    padding: 10px;
    min-height: 623px;
}

@media (max-width: 1400px) {
.post-item.mini {
    margin-bottom: 20px;
    min-height: 324px;
}
.post-item {
    background: #fff;
    padding: 10px;
    min-height: 668px;
}

}

@media (max-width: 600px) {
    section h2 {
        font-size: 20px;
        line-height: 28px;
    }
.row.main-header {
    margin: 0 15px !important;
}
.dropbutton img {
    margin-left: 0px;
    margin-top: 8px;
    position: absolute;
    bottom: 0;
    right: 3px;
    z-index: -1;
}
.textvideo .textarea {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    padding-right: 20px;
    margin-bottom: 20px;
}
.videoarea {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.videoarea iframe {
    width: 100%;
    min-height: 180px;
}
.row_images.row .col-sm-4 {
    width: 33%;
}
.s-map__buttons {
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 121;
}
#frame {
    overflow: hidden;
    width: 100%;
    height: 280px;
    margin-top: 0px;
}
#videyoutube {
    position: relative;
    margin-bottom: -60px;
    padding-bottom: 42%;
    display: none;
}
}


.headbanner {
    position: relative;
}
.headbanner h1 {
    position: absolute;
    top: 120px;
    width: 100%;
    text-align: center;
    font-size: 60px;
    color: #fff;
    text-shadow: 3px 3px 3px #000;
}
.headbanner span {
    position: absolute;
    top: 220px;
    width: 100%;
    padding: 0 20%;
    text-align: center;
    font-size: 40px;
    color: #fff;
    text-shadow: 3px 3px 3px #000;
}
form.wpcf7-form.init .row {
    margin-top: 30px;
}
form.wpcf7-form.init label.textlabel {
    margin: 5px 0;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: #444;
}
.stars {
    text-align: center;
}
span.wpcf7-form-control-wrap textarea {
    width: 100%;
}
.starratingawesome:not(:checked) > label {
    float: right;
    /* padding: 0 .1em; */
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #E1E5ED;
    line-height: 1.2em;
    width: 40px;
    font-size: 100%;
    margin-right: 0.2em;
}
.starratingawesome {
    font-size: 28px;
}



.footer{
    background-color: #1E1E1E !important;
    margin-top: 30px;  
    padding: 20px 0 30px !important;
    color: #FFF;
}
.footer a {
    color: #FFF;
}
.footer a:hover {
    color: #ECE63E;
}
.footer h4{
 font-size: 15px;
    margin-top: 38px;
}
.footer li{
      list-style: none;  
}
.footer ul{
    padding-left:0px;
}
.footer img{
    float:right;
}
.col-md-2.col-12.offset-md-4.align-self-center.logo img {
    max-width: 100%;
}


@media (max-width: 600px) {
	.headbanner h1 {
    font-size: 34px;
}
.headbanner span {
    font-size: 24px;
    display: none;
}
.headbanner h1 {
    top: 36px;
}
.career-container > div.container.feedback-form {
    padding: 10px 20px 100px 20px;
    background-color: #FDFDFE;
    max-width: 1140px;
}
label.add-files {
    font-size: 11px;
}
.col-sm-1.col-xs-4 {
    position: absolute;
    right: 20px;
    text-align: right;
}
}

form#loginform {
    width: 360px;
    margin: 20px auto;
    background: #fcfcfc;
    box-shadow: 1px 1px 10px #ccc;
    padding: 15px;
    text-align: center;
}
form#loginform label {
    font-size: 14px;
    color: #000;
    text-align: center;
    display: block;
}
form#loginform input {
    text-align: left;
    width: 80%;
}
form#loginform input#rememberme,form#loginform input#wp-submit {
    text-align: unset;
    width: unset;
}
form#loginform input#wp-submit {
    background: #003087;
    color: #fff;
    border: none;
    height: 40px;
    width: 100px !important;
}
form#loginform input#wp-submit:hover {
    background: #444;
}

table.widefat td a {
    color: #04a5d4;
}
table.widefat td a:hover {
    color: #003087 !important;
}

/*.wpcf7 form.sent .wpcf7-response-output {
    position: fixed;
    z-index: 99999;
    top: 30%;
    height: 100px;
    line-height: 90px;
    color: #46b450;
    background: #fff;
    width: 420px;
    left: calc(50% - 210px);
    outline: 1000px solid rgb(0 0 0 / 0.6);
}*/


.headbanner img {
    max-width: 100%;
}
span.wpcf7-form-control-wrap {
    margin-bottom: 20px;
    display: block;
}
/*#recipients >option:last-child {
    display: none;
}
*/



.fielditem.hidden {
    display: none;
}
.staritem.hidden {
    display: none;
}

.companyform {
    text-align: center;
}
.companyform h1 {
    margin: 20px 0 40px 0;
}
.staritem {
    margin-bottom: 16px;
}
.staritem>label, .fielditem>label {
    font-weight: 600;
    font-size: 18px;
    text-transform: none;
    width: 100%;
    display: block;
}
.fielditem textarea {
    padding: 10px 15px;
}
span.wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 0em;
}
span.wpcf7-list-item:nth-child(2n) {
    display: inline-block;
    margin: 0 5px 0 5px;
}
span.wpcf7-list-item {
    min-width: 50px;
}
input#send {
    height: 40px;
    border: none;
    background: #da291c;
    width: 100px;
    color: #fff;
        display: block;
    margin: 0 auto;
}
input#send:hover {
    background: #e6736a;
}
.socials {
    text-align: center;
    margin: 40px 0 0 0;
}
.socials img {
    max-width: 60px;
    margin: 10px;
}

span.wpcf7-form-control-wrap.enquiry-type, span.wpcf7-form-control-wrap.enquiry-rate, span.wpcf7-form-control-wrap.recipients {
    width: 50%;
    margin: 0 auto 20px auto;
}
span.wpcf7-form-control-wrap.recipients select {
    width: 300px;
}
a.linktoform {
    color: #000091;
    text-transform: none;
    font-weight: 400;
    margin: 30px auto;
    display: block;
}
a.linktoform:hover {
    color: #000091 !important;
    text-decoration: underline !important;
}
.fielditem~div>div {
    margin: 0 auto;
    text-align: center;
}
span.wpcf7-form-control-wrap.recipients select {
    width: 300px;
    padding: 3px;
    text-align: center !important;
}
span.wpcf7-form-control-wrap.recipients select option{
    text-align: center !important;
}

.wpcf7 form.sent .wpcf7-response-output {
    position: fixed;
    top: 35%;
    height: 140px;
    line-height: 130px;
    font-size: 20px;
    color: #000;
    background: #fff;
    font-weight: 600;
    width: 30%;
    text-align: center;
    left: calc(50% - 15%);
    box-shadow: 0 1px 1px rgba(0,0,0,.085);
    outline: solid rgba(0, 0, 0, .25) 10000px;
    display: none !important;
}
.wpcf7 form.sent .wpcf7-response-output {
    border-color: #04a5d4;
}


    #formsuccess {
        /*display: none;*/
        position: fixed;
        top: 35%;
        height: 140px;
        line-height: 130px;
        font-size: 20px;
        color: #000;
        background: #fff;
        font-weight: 600;
        width: 50%;
        text-align: center;
        left: calc(50% - 25%);
        box-shadow: 0 1px 1px rgba(0,0,0,.085);
        outline: solid rgba(0, 0, 0, .25) 10000px;
        border-color: #04a5d4;
    }
    #formsuccess.modalshow {
        display: block;
    }

    span.ajax-loader+a {
    color: #000;
}

i.fa-angle-double-right+a {
    color: #5db4ec;
    text-transform: none;
    font-weight: 400;
    margin: 30px auto;
    display: inline-block;
    vertical-align: middle;
}
i.fa-angle-double-right+a:hover {
    color: #003087 !important;
}
i.fa.fa-angle-double-right {
    margin: 29px 0;
    vertical-align: middle;
    color: #000091;
}


/*.fa-star:before {
    content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-regular.svg);
    font-size: 20px;
    width: 30px !important;
    height: 30px !important;
    display: block;
}
.fa-star:hover:before {
    content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-solid.svg);
    font-size: 20px;
    width: 30px !important;
    height: 30px !important;
    display: block;
}*/


.starratingawesome > input:checked + label:hover i:before, 
.starratingawesome > input:checked + label:hover ~ label i:before, 
.starratingawesome > input:checked ~ label:hover i:before, 
.starratingawesome > input:checked ~ label:hover ~ label i:before, 
.starratingawesome > label:hover ~ input:checked ~ label i:before {
    color: #EEAA00;
    content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-solid2.png);
    width: 32px;
    height: 32px;
    display: block;
}
.starratingawesome:not(:checked) > label:hover i:before, 
.starratingawesome:not(:checked) > label:hover ~ label i:before {
    color: #FFD700;
    content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-solid2.png);
    width: 32px;
    height: 32px;
    display: block;
}
.starratingawesome > input:checked ~ label i:before {
    color: #5DB4EC;
        content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-solid2.png);
    width: 32px;
    height: 32px;
    display: block;
}
.starratingawesome:not(:checked) > label i:before {
    float: right;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    color: #E1E5ED;
    line-height: 1.2em;
    font-size: 100%;
    margin-right: 0.2em;
    content: url(https://rate.hegelmann.com/wp-content/themes/carrier/images/star-regular2.png);
    width: 32px;
    height: 32px;
    display: block;

}

label.categoryLabel{
    margin-bottom: 10px;
}
label.categoryLabel>span{
    color: red;
    font-size: 25px;
}
.categorList>span{
    all: initial;
    display: inline;
    margin: 10px;
    font-size: 18px;
    font-family: sans-serif;
}
.categoryCheckbox>input{
    height: 18px;
    width: 18px;
    vertical-align: middle;
    bottom: 1px;
}
.categoryCheckbox>span{
    vertical-align: middle;
}